#detail {
	flex-grow: 1;
	padding-top: 7rem;
	background: url('../../asset/type-bg.jpg') no-repeat center;
	background-size: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.detail-container {
	background-color: rgba(25, 20, 20, 0.6);
	color: white;
	border-radius: 2%;
}

.detail-name {
	text-align: center;
	font-weight: 700 !important;
	padding-top: 1rem;
}

#detail-pokemon-comp {
	display: flex;
	margin: 0 0 2rem 2rem;
}
.detail-pokemon-stats {
	padding-bottom: 1rem;
	font-size: 1.5rem;
	font-weight: bold;
}
#detail-pokemon-list-stats {
	margin: auto;
	text-align: center;
	margin: 5rem;
}

@media screen and (max-width: 500px) {
	#detail {
		background: url('../../asset/type-bg.jpg') no-repeat center;
		background-size: cover;
	}
	.detail-container {
		width: 85%;
		margin-bottom: 1rem;
	}

	.detail-name {
		font-size: 2rem !important;
	}

	.detail-pokemon-img {
		width: 60%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 0 2rem 0;
	}

	#detail-pokemon-comp {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.detail-pokemon-stats {
		padding-bottom: 0.5rem;
		font-size: 1.3rem;
	}
	#detail-pokemon-list-stats {
		margin: 0;
	}
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	#detail {
		background: url('../../asset/type-bg.jpg') no-repeat center;
		background-size: cover;
	}
	.detail-container {
		width: 85%;
		margin-bottom: 1rem;
	}

	.detail-name {
		font-size: 3rem !important;
	}

	.detail-pokemon-img {
		width: 45%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 0 2rem 0;
	}

	#detail-pokemon-comp {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.detail-pokemon-stats {
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}
	#detail-pokemon-list-stats {
		margin: 0;
	}
}
