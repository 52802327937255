.inscription-container {
	background: url('../../asset/bg-inscription-2048.jpg') no-repeat center;
	background-size: 100%;
	padding-top: 4.5rem;
	display: flex;
	justify-content: center;
	/* padding:3%; */
	padding-top: 5%;
	flex-grow: 1;
	color: white;
}
.inscription-box {
	margin-left: 50%;
}
.inscription-title {
	font-size: 2rem;
	padding: 1rem 0 1rem 0;
	font-weight: 700;
}

.inscription-modal {
	display: flex;
}
.inscription-input-label {
	color: white !important;
	margin-top: 1em;
	text-align: center;
	font-weight: 600 !important;
}
.inscription-input {
	color: white !important;
}
#inscription-submit:hover {
	background-color: yellow !important;
}
.inscription-swal-timer {
	color: #3383d2;
}
#inscription-submit {
	margin-top: 0.5rem;
	color: #3383d2;
	font-weight: 600;
	background-color: white !important;
}
/* .MuiInputLabel-root {
	color: white !important;
	font-size: 1.2rem;
} */

@media (max-width: 500px) {
	.inscription-container {
		background: url('../../asset/bg-inscription-2048.jpg') no-repeat center;
		background-size: cover;
		display: flex;
		justify-content: center;
		color: white;
		align-items: center;
	}
	.inscription-box {
		font-size: 1.5rem !important;
		background: rgba(0, 0, 0, 0.6); /* Couleur de fond avec transparence */
		margin: auto;

		border-radius: 5%;
		width: 60%;
	}

	/* .inscription-title {
		font-size: 1.5rem;
		padding: 1rem 0 1rem 0;
	} */
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	.inscription-container {
		background: url('../../asset/bg-inscription-2048.jpg') no-repeat center;
		background-size: cover;
		display: flex;
		justify-content: center;
		color: white;
		align-items: center;
	}
	.inscription-box {
		font-size: 1.5rem !important;
		background: rgba(0, 0, 0, 0.6); /* Couleur de fond avec transparence */
		margin: auto;

		border-radius: 5%;
	}

	.inscription-title {
		font-size: 2rem;
		padding: 1rem 0 1rem 0;
	}

	.inscription-input {
		color: white !important;

		text-align: center;
		font-size: 1.3rem !important;
	}

	.inscription-input-label {
		color: white !important;
		margin-top: 1rem;
		text-align: center;
		font-weight: 700 !important;
		font-size: 1.5rem !important;
	}
}
