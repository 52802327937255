.connexion-container {
	background: url('../../asset/login\ calque.png') no-repeat center;
	background-size: 100%;

	flex-grow: 1;
	display: flex;
	justify-content: center;
	color: white !important;
	align-items: center;
}

.connexion-title {
	font-size: 2rem;
	padding: 1rem 0 1rem 0;
	align-items: center;
	justify-content: center;
	display: flex;
}

.connexion-subcontainer {
	height: 20rem;
	width: 20rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-weight: 700;
}
.connexion-input-label {
	margin-top: 2rem;
	text-align: center;
	color: white !important;
	justify-content: center;
	font-weight: 600 !important;
}

.css-17ajtcg {
	height: 20rem;
}

.connexion-button-container {
	padding-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

#connexion-submit {
	color: #3383d2;
	font-weight: 600 !important;
	background-color: white !important;
}
#connexion-submit:hover {
	background-color: yellow !important;
}
.connexion-input {
	color: white !important;
}

@media screen and (max-width: 500px) {
	.connexion-container {
		background: url('../../asset/login.jpg') no-repeat center;
		background-size: cover;

		justify-content: center;
		color: white;
		height: 20rem;
	}
	.connexion-subcontainer {
		font-size: 1.5rem !important;
		background: rgba(0, 0, 0, 0.7); /* Couleur de fond avec transparence */
		margin: 7rem 0rem 2rem 0rem;
		height: 20rem;
		width: 20rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 5%;
	}
	.connexion-title {
		font-size: 2rem;
		padding: 1rem 0 1rem 0;
	}
	.connexion-input-label {
		margin-top: 1.5rem;
		text-align: center;
		color: white !important;
	}

	.connexion-button-container {
		padding-top: 2rem;
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	.connexion-container {
		background: url('../../asset/login.jpg') no-repeat center;
		background-size: cover;

		justify-content: center;
		color: white;
		height: 20rem;
	}
	.connexion-subcontainer {
		font-size: 1.5rem !important;
		background: rgba(0, 0, 0, 0.7); /* Couleur de fond avec transparence */
		margin: 7rem 0rem 2rem 0rem;
		height: 23rem;
		width: 23rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 5%;
	}
	.connexion-title {
		font-size: 3rem;
		padding: 1rem 0 1rem 0;
	}
	.connexion-input-label {
		margin-top: 1.5rem;
		text-align: center;
		color: white !important;
		font-size: 1.5rem !important;
	}

	.connexion-button-container {
		padding-top: 2rem;
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
}
