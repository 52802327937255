.pokemon-container {
	text-align: center;
	width: fit-content;
	height: fit-content;
	border-radius: 1rem;
	margin: 1rem;
	padding: 1rem;
	image-rendering: auto;
}

.pokemon-img {
	padding: 1rem 1rem 1rem 1rem;
	cursor: pointer;
	transition: transform 0.5s ease-in;
	border-radius: 1rem;
}

.pokemon-img:hover {
	transform: scale(1.1);
}

.pokemon-nom {
	padding-top: 0.5rem;
	font-size: 2rem;
	font-weight: bolder;
	cursor: pointer;
	margin: 0;
	padding-bottom: 0.5rem;
	width: 100%;
}

.pokemon-title {
	display: flex;
	padding-top: 1rem;
}

.pokemon-button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	outline: none;
}

.pokemon-icon {
	color: blue;
	cursor: pointer;
	padding: 0;
	border: white;
	background-color: transparent;
}
.MuiSvgIcon-root {
	background-color: none;
}
h3 {
	margin: 0;
}

.pokemon-modal-text {
	font-weight: 700;
}

@media (max-width: 500px) {
	.pokemon-container {
		flex-direction: column;
		width: 8rem;
		max-height: 9.5rem;
		margin: 0.5rem 0.5rem 0.5rem 0;
		margin-right: 0;
	}
	.pokemon-nom {
		font-weight: bold;
		font-size: 1.5rem;
	}
	.pokemon-img {
		max-width: 10rem;
		width: fit-content;
		max-height: 6.5rem;
	}

	.pokemons {
		justify-content: space-between;
	}
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	.pokemons {
		margin-top: 13%;
	}

	.pokemon-container {
		flex-direction: column;
		width: 10rem;
		max-height: 9.5rem;
		margin: 0.5rem 0.5rem 0.5rem 0;
		margin-right: 0;
	}

	.pokemon-img {
		max-width: 10rem;
		width: fit-content;
		max-height: 6.5rem;
	}

	.pokemons {
		justify-content: space-between;
	}
}
