#deck {
	padding: 3%;
	padding-top: 5%;
	flex-grow: 1;
}

.deck-container {
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.deck-image {
	padding-bottom: 0.7rem;
}

.deck-title {
	font-weight: 700 !important;
	padding-bottom: 3%;
	text-align: center;
	font-size: 2rem;
}

.deck-button {
	text-align: center;
}

#deck-nodeck-text {
	text-align: center;
}

#deck-nodeck-link {
	text-decoration: none;
	font-weight: bold;
	color: #f08080;
}

.deck-pokemon {
	text-align: center;
	width: fit-content;
	height: fit-content;
	border: black 1px solid;
	border-radius: 1rem;
	margin: 1em 5em 1em 5em;
	background-color: lightcoral;
	padding: 1rem;
	image-rendering: auto;
}

.deck-pokemon-nom {
	padding-top: 0.5rem;
	font-weight: bold;
	font-size: 2rem;
	margin: 0;
	padding-bottom: 1rem;
	width: 100%;
	color: white;
}

.deck-type-comp-container {
	font-weight: 700;
}
.deck-type-comp {
	padding-top: 0.4em;
	font-size: 1.5rem;
	color: white;
}

@media screen and (max-width: 500px) {
	#deck-nodeck-text {
		margin-top: 10%;
		font-size: 1.5rem !important;
	}
	.deck-title {
		margin-top: 20%;
	}

	.deck-container {
		margin-bottom: 2rem;
	}

	.deck-button {
		margin: 2rem 0 2rem 0;
	}
	.deck-pokemon-nom {
		font-size: 2rem;
	}

	.deck-pokemon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85%;
		margin: 0 0 2rem 0;
	}

	.deck-pokemon-img {
		width: 60%;
		padding: 1.5rem 0 1.5rem 0;
	}
	.deck-type-comp {
		padding: 0 0 0.5rem 0;
		font-size: 1.5rem;
	}
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	#deck {
		padding-top: 10%;
	}

	.deck-title {
		margin-top: 5%;
		font-size: 2.5rem;
	}

	.deck-reset-button {
		font-size: 1.5rem !important;
	}

	#deck-nodeck-text {
		font-size: 2rem !important;
	}
	.deck-pokemon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 75%;
		margin: 0 0 2rem 0;
	}

	.deck-pokemon-nom {
		font-size: 3rem;
	}
	.deck-pokemon-img {
		width: 70%;
		padding: 1.5rem 0 1.5rem 0;
	}
	.deck-type-comp {
		padding: 0 0 0.5rem 0;
		font-size: 1.5rem;
	}
}
