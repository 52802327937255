/* #navbar{
    height:10vh;
} */
.nav-menu {
	padding: 0.5rem;
	text-decoration: none;
	transition: all 0.2s ease-in;
	color: black;
	font-weight: bold;
}

.nav-menu:hover {
	background-color: #c6c5ca;
	border-radius: 5%;
}

.nav-menu-connexion {
	font-family: 'Segeo Ui', sans-serif !important;
}
.nav-menu-connexion:hover {
	background-color: lightblue;
}

.nav-pokedex {
	display: flex;
	align-items: center;
	width: 25rem;
}
#nav-search-responsive {
	display: none;
}

#nav-pokedex-typo {
	padding-left: 1rem;
	color: black;
}
/* #deconnexion{
    background-color:#C6C5CA ;
    border:1px solid black;
} */
#navbar-toolbar {
	/* z-index:99;
    position:fixed;
    width:100%; */
	background-color: lightcoral;
	justify-content: space-between;
}

.nav-search {
	background-color: white;
}

/* .nav-menu:hover{
    box-sizing: border-box;
    border-bottom: solid white .15em;
} */

.nav-menu:active {
	text-decoration: underline;
	text-decoration-color: white;
}

.nav-button-connexion:hover {
	background-color: lightblue;
}
.nav-logo {
	width: 2.5em;
	height: 2.5em;
}

.nav-pokedeck-icon {
	width: 13rem;
}
.nav-element-right-empty {
	width: 8.3vw;
}
#nav-burger {
	display: none;
}
#nav-search-responsive {
	display: none;
}

#navbar-container-menu-burger {
	display: none;
}
/* 
@media (max-width: 500px) {
	#nav-burger {
		display: inline;
		padding: 0.3rem 2rem 0 0;
		transform: scale(1.5);
	}
	#navbar-container-menu {
		display: none;
	}
	.nav-search {
		display: none !important;
	}
	#nav-search-responsive {
		display: inline-grid;
		transform: scale(1.6);
		padding: 0.3rem 0 0 1.5rem;
	}
	.nav-element-right {
		display: none;
	}
	#navbar-toolbar {
		flex-direction: column;
	}
	.nav-pokedex {
		width: fit-content;
	}

	#navbar-container-menu-burger {
		display: block;
	}
	.nav-element-right {
		display: none;
	}

	.nav-element-right.nav-search-visible {
		display: block !important;
	}
} */
@media (max-width: 500px) {
	#nav-burger {
		display: inline;
		transform: scale(1.5);
		margin-top: 3%;
		width: 1rem;
	}
	#navbar-container-menu {
		display: none;
	}
	/* .nav-search {
		display: none !important;
	} */
	#nav-search-responsive {
		display: inline-grid;
		transform: scale(1.6);
		padding: 0.3rem 0 0 0.8rem;
		width: 100%1;
	}
	.nav-element-right {
		display: none;
	}
	#navbar-toolbar {
		flex-direction: column;
	}
	.nav-pokedex {
		width: 110%;
	}

	.nav-logo {
		width: 2rem;
		height: 2rem;
	}
	#navbar-container-menu-burger {
		display: block;
	}
	.nav-element-right.nav-search-visible {
		display: flex;
		width: 50%;
		height: 100%;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;
	}
	/* Icon Menu burger */
	.nav-burger-icon {
		fill: black !important;
	}
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	#nav-burger {
		display: inline;
		transform: scale(1.5);
		margin-top: 3%;
		width: 1rem;
	}
	#navbar-container-menu {
		display: none;
	}
	/* .nav-search {
		display: none !important;
	} */
	#nav-search-responsive {
		display: inline-grid;
		transform: scale(1.6);
		padding: 0.3rem 0 0 0.8rem;
		width: 100%;
	}
	.nav-element-right {
		display: none;
	}
	#navbar-toolbar {
		flex-direction: column;
	}
	.nav-pokedex {
		display: flex;
		justify-content: space-evenly;
	}

	.nav-logo {
		width: 2rem;
		height: 2rem;
	}
	#navbar-container-menu-burger {
		display: block;
	}
	.nav-element-right.nav-search-visible {
		display: flex;
		width: 50%;
		height: 100%;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;
	}
	.nav-menu {
		font-size: 1.2rem;
	}

	.nav-menu-disconnect-button {
		font-size: 1.2rem !important;
	}
	/* Icon Menu burger */
	.nav-burger-icon {
		fill: black !important;
	}
}
