/* #types {
	padding-top: 4.5rem;
	flex-grow: 1;
}
.types-pokemon {
	background: url('/public/img/background-types.png') no-repeat center;
	background-size: cover;

	padding: 3%;
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
	justify-content: space-between;
} */
#types {
	padding-top: 4.5rem;
	flex-grow: 1;
	display: flex;
	flex-direction: column; /* Assurez-vous que l'enfant s'étend verticalement */
}

.types-pokemon {
	background: url('../../asset/background-types.png') no-repeat center;
	background-size: cover;

	flex-grow: 1; /* Faites en sorte que cet enfant occupe tout l'espace disponible */

	padding: 3%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
