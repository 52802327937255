.detail-type {
	/* background: url('../../../public/img/bg-types.png') center no-repeat;
	background-size: 100%; */
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 5%;
}
.detail-type-name {
	color: white;
	font-weight: 700 !important;
	font-size: 3rem;
	padding-bottom: 1rem;
}

.detail-type-container {
	-webkit-animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	margin: 1rem;
	padding: 1rem;
	background-color: lightcoral;
	color: white;
	border-radius: 2%;
	text-align: center;
	margin: 1.2rem;
	font-size: 1.5rem;
	font-weight: bold;
	image-rendering: auto;
}

.detail-type-img {
	transition: transform 0.5s ease-in;
}
.detail-type-img:hover {
	transform: scale(1.1);
}
.detail-type-comp {
	padding-top: 0.4em;
	font-size: 1.2em;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-30 17:51:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@media screen and (max-width: 500px) {
	.detail-type {
		margin-top: 5rem;
	}
	.detail-type-container {
		background: rgba(212, 61, 61, 0.6);
		font-size: 2rem !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 2rem 0 2rem !important;
	}
	.detail-type-name {
		font-size: 2rem !important;
	}

	.detail-type-img {
		width: 60%;
		padding: 2rem 0 2rem 0;
	}
	.detail-type-comp {
		padding-top: 0.4em;
		font-size: 1.3rem;
	}
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
	.detail-type {
		margin-top: 3rem;
	}
	.detail-type-container {
		background: rgba(212, 61, 61, 0.6);

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-width: 35%;
	}
	.detail-type-name {
		font-size: 2.5rem !important;
	}

	.detail-type-img {
		width: 60%;
		padding: 2rem 0 2rem 0;
	}
	.detail-type-comp {
		padding-top: 0.4em;
		font-size: 1.5rem;
	}
}
